body{
	margin: 12px;
	padding: 10px;
	align-content: start;
	align-items: center;
	min-height: 95vh;
	font-family: 'Jost', sans-serif;
	background: linear-gradient(to bottom, #3c99ac, #145197, #122d77, #01113f);
}
.main{
	background: red;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
}
#chk{
	display: none;
}
h1{
	color: #fff;
	justify-content: center;
    display: flex;
}
label{
	color: #fff;
	font-size: 2.3em;
	justify-content: center;
	display: flex;
	margin: 50px;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}
input{
	width: 40%;
	background: #e0dede;
	justify-content: center;
	display: flex;
	margin: 20px auto;
	padding: 12px;
	border: none;
	outline: none;
	border-radius: 5px;
}
button{
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: #002792;
	font-size: 1em;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 10px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
button:hover{
	background: #3c99ac;
}
link {
	text-decoration: 'none';
	color:#fff;
}
table, th, td {
	border: 1px solid white;
	color:#fff;
	text-align: center;
  }